export const Alfajores = {
    ID: 44787,
    NAME: 'Celo (Alfajores Testnet)',
    NETWORK: 'Celo-testnet',
    ICONURL: 'https://cryptologos.cc/logos/celo-celo-logo.svg?v=023',
    ICON_BG: '#0320fc',
    NATIVECURRENCT_NAME: 'CELO',
    NATIVECURRENCT_SYMBOLS: 'CELO',
    NATIVECURRENCT_DECIMALS: 18,
    NATIVECURRENCT_RPCURL_DEFAULT: 'https://alfajores-forno.celo-testnet.org',
    NATIVECURRENCT_BLOCKEXPLORER_DEFAULT_NAME: 'Alfajores-Test-Scanner',
    NATIVECURRENCT_BLOCKEXPLORER_DEFAULT_URL: 'https://alfajores-blockscout.celo-testnet.org',
    TESTNET: true,
}

export const Celo = {
    ID: 42220,
    NAME: 'Celo',
    NETWORK: 'celo',
    ICONURL: 'https://cryptologos.cc/logos/celo-celo-logo.svg?v=023',
    ICON_BG: '#fff',
    NATIVECURRENCT_NAME: 'CELO',
    NATIVECURRENCT_SYMBOLS: 'CELO',
    NATIVECURRENCT_DECIMALS: 18,
    NATIVECURRENCT_RPCURL_DEFAULT: 'https://forno.celo.org',
    NATIVECURRENCT_BLOCKEXPLORER_DEFAULT_NAME: 'Celo-Scanner',
    NATIVECURRENCT_BLOCKEXPLORER_DEFAULT_URL: 'https://explorer.celo.org',
    TESTNET: false,
}
export const Baklava = {
    ID: 62320,
    NAME: 'Celo (Baklava Testnet)',
    NETWORK: 'Celo (Baklava Testnet)',
    ICONURL: 'https://cryptologos.cc/logos/celo-celo-logo.svg?v=023',
    ICON_BG: '#0a0a0a',
    NATIVECURRENCT_NAME: 'CELO',
    NATIVECURRENCT_SYMBOLS: 'CELO',
    NATIVECURRENCT_DECIMALS: 18,
    NATIVECURRENCT_RPCURL_DEFAULT: 'https://baklava-forno.celo-testnet.org',
    NATIVECURRENCT_BLOCKEXPLORER_DEFAULT_NAME: 'Celo-Scanner',
    NATIVECURRENCT_BLOCKEXPLORER_DEFAULT_URL: 'https://baklava-blockscout.celo-testnet.org',
    TESTNET: false,
}